// extracted by mini-css-extract-plugin
export var artist = "styles-module--artist--82cf2";
export var artistsWrapper = "styles-module--artistsWrapper--d6871";
export var backArrow = "styles-module--backArrow--0fe8e";
export var date = "styles-module--date--a6f07";
export var galleryImage = "styles-module--galleryImage--9df04";
export var galleryWrapper = "styles-module--galleryWrapper--c496d";
export var link = "styles-module--link--6c724";
export var linksWrapper = "styles-module--linksWrapper--2f6d3";
export var navigate = "styles-module--navigate--f82bf";
export var root = "styles-module--root--97a92";
export var subtitle = "styles-module--subtitle--cf94f";
export var title = "styles-module--title--66110";
export var titleWrapper = "styles-module--titleWrapper--cae48";