import * as React from "react"
import { navigate } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import * as styles from './styles.module.scss'
import backArrow from '../../svg/back-arrow.svg'
import CustomCarousel from '../../components/CustomCarousel/CustomCarousel'

const Fair = ({ pageContext }) => {
  const {title, subtitle, date, artists, pressRelease, checkList, imageGallery} = pageContext.content

  return (
    <Layout>
      <Seo title="Fairs" />
      <div className={styles.root}>
        <div className={styles.navigate}>
          <button className={styles.backArrow} onClick={() => navigate(-1)}>
            <img data-type='icon' src={backArrow} />
          </button>
          <div className={styles.title}>Fair</div>
        </div>

        <div className={styles.titleWrapper}>
          <div className={styles.title}>{title}</div>
          <div className={styles.subtitle}>{subtitle}</div>
          <div className={styles.date}>{date}</div>
        </div>

        {artists ?
          <div className={styles.artistsWrapper}>
            {artists.map((artist, index) => <div key={index} className={styles.artist}>{artist}</div>)}
          </div>
          : null
        }

        <div className={styles.linksWrapper}>
          {pressRelease ? <div className={styles.link}><a href={pressRelease} target='_blank'>pressRelease</a></div> : null}
          {checkList ? <div className={styles.link}><a href={pressRelease} target='_blank'>checkList</a></div> : null}
        </div>

        <div className={styles.galleryWrapper}>
          <CustomCarousel
            images={imageGallery}
          />
        </div>
      </div>
    </Layout>
  )
}

export default Fair
